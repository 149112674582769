import axios from 'axios';
import AuthService from './auth.service';

const instance = axios.create({
    baseURL: process.env.NODE_ENV == 'development' ? process.env.PUBLIC_URL + '/api' : process.env.REACT_APP_API_URL,
    withCredentials: true,
    headers: {
        Accept: 'application/json',
        'Access-Control-Allow-Origin': process.env.REACT_APP_API_URL,
        'Access-Control-Allow-Methods': 'GET,PUT,POST,DELETE,PATCH,OPTIONS'
    }
});

const logoutAndRedirect = () => {
    AuthService.logout()
        .then(() => {
            navigate('/login');
            localStorage.clear();
            document.cookie = '';
            window.location.href = '/login';
        })
        .catch(() => {
            localStorage.clear();
            document.cookie = '';
            window.location.href = '/login';
        });
};

let isRefreshing = undefined;

const refreshToken = async (config) => {
    config._retry = true;
    isRefreshing = true;
    try {
        await AuthService.refreshToken();
        isRefreshing = false;
        return instance(config);
    } catch (_error) {
        if (_error.response) {
            if (_error.response.status === 500 && _error.response.data.message.includes('Token could not be parsed')) {
                logoutAndRedirect();
            } else if (_error.response.status === 401 && err.response.data.type === 'RELOGIN') {
                logoutAndRedirect();
            }
        }
        return Promise.reject(_error);
    }
};

const handleResponseError = async (err) => {
    const originalConfig = err.config;

    if (originalConfig.url !== '/login' && err.response) {
        // Access Token was expired
        if (err.response.status === 401 && checkLogoutCondition(err.response.data)) {
            logoutAndRedirect();
        } else if (err.response.status === 401 && err.response.data.can_refresh && !originalConfig._retry) {
            if (!isRefreshing) {
                isRefreshing = true;
                try {
                    return await refreshToken(originalConfig);
                } finally {
                    isRefreshing = false;
                }
            }
        }
        return Promise.reject(err);
    }

    return Promise.reject(err);
};

const checkLogoutCondition = (errorData) => {
    if (errorData.type === 'RELOGIN') return true;
    else if (errorData.message === 'Unauthenticated' && !errorData.can_refresh) return true;
    else if (errorData.message.includes('blacklisted')) return true;
    else return false;
};

instance.interceptors.response.use((res) => {
    return res;
}, handleResponseError);

export default instance;
