import { Dialog, DialogContent } from '@mui/material';
import { Box } from '@mui/system';
import closeModal from '../../assets/images/icons/close_modal.png';

// eslint-disable-next-line react/prop-types
const MainModal = ({ handleCloseModal, openModal, content }) => {
    return (
        <Dialog onClose={handleCloseModal} open={openModal}>
            <DialogContent sx={{ maxWidth: '500px' }}>
                <Box style={{ position: 'absolute', right: '20px', top: '20px', cursor: 'pointer' }} onClick={handleCloseModal}>
                    <img src={closeModal} alt="" />
                </Box>
                <Box pt={3}>{content}</Box>
            </DialogContent>
        </Dialog>
    );
};

export default MainModal;
