import PropTypes from 'prop-types';

// material-ui
import { useTheme } from '@mui/material/styles';
import { Box, Drawer, useMediaQuery } from '@mui/material';

// third-party
import PerfectScrollbar from 'react-perfect-scrollbar';
import { BrowserView, MobileView } from 'react-device-detect';

// project imports
import MenuList from './MenuList';

import { drawerWidth } from 'store/constant';
import { useSelector } from 'react-redux';

import { useNavigate } from 'react-router';
import { getLogoPath, getLogoPath2 } from 'helpers/logo';
import { getAppName } from 'helpers/appName';

// ==============================|| SIDEBAR DRAWER ||============================== //

const openedMixin = (theme) => ({
    width: drawerWidth,
    transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen
    }),
    overflowX: 'hidden'
});

const closedMixin = (theme) => ({
    transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen
    }),
    overflowX: 'hidden',
    width: `calc(${theme.spacing(7)} + 1px)`,
    [theme.breakpoints.up('sm')]: {
        width: `calc(${theme.spacing(8)} + 1px)`
    }
});

// eslint-disable-next-line react/prop-types
const Sidebar = ({ drawerOpen, drawerToggle, window }) => {
    const theme = useTheme();
    const matchUpMd = useMediaQuery(theme.breakpoints.up('md'));
    const user = JSON.parse(localStorage.getItem('user_info'));
    const leftDrawerOpened = useSelector((state) => state.customization.opened);
    const navigate = useNavigate();
    const app_name = getAppName();

    const drawer = (
        <>
            <Box sx={{ display: { xs: 'block', md: 'block' } }}>
                <Box
                    onClick={() => navigate('/dashboard')}
                    sx={{ display: 'flex', p: app_name === 'dewaweb' ? 1 : 2, mx: 'auto', paddingLeft: '20px', cursor: 'pointer' }}
                >
                    {leftDrawerOpened ? <img width={150} src={getLogoPath()} alt="" /> : <img width={25} src={getLogoPath2()} alt="" />}
                </Box>
            </Box>
            <BrowserView>
                <PerfectScrollbar
                    component="div"
                    style={{
                        height: !matchUpMd ? 'calc(100vh - 56px)' : 'calc(100vh - 88px)',
                        paddingTop: '2.5rem',
                        paddingRight: leftDrawerOpened ? '15px' : '5px'
                    }}
                >
                    <MenuList />
                </PerfectScrollbar>
            </BrowserView>
            <MobileView>
                <Box sx={{ px: 2 }}>
                    <MenuList />
                </Box>
            </MobileView>
        </>
    );

    const container = window !== undefined ? () => window.document.body : undefined;

    return (
        <Box component="nav" sx={{ flexShrink: { md: 0 }, width: matchUpMd ? drawerWidth : 'auto' }} aria-label="mailbox folders">
            <Drawer
                container={container}
                variant={matchUpMd ? 'permanent' : 'temporary'}
                anchor="left"
                open={drawerOpen}
                onClose={drawerToggle}
                sx={{
                    '& .MuiDrawer-paper': {
                        width: drawerWidth,
                        background: theme.palette.background.default,
                        color: theme.palette.text.primary,
                        marginTop: user?.admin_id === 1 ? '25px' : 0,
                        borderRight: '1px solid #EEEEEE',
                        ...(drawerOpen && {
                            ...openedMixin(theme),
                            '& .MuiDrawer-paper': openedMixin(theme)
                        }),
                        ...(!drawerOpen && {
                            ...closedMixin(theme),
                            '& .MuiDrawer-paper': closedMixin(theme)
                        })
                    }
                }}
                ModalProps={{ keepMounted: true }}
                color="inherit"
            >
                {drawer}
            </Drawer>
        </Box>
    );
};

Sidebar.propTypes = {
    drawerOpen: PropTypes.bool,
    drawerToggle: PropTypes.func,
    window: PropTypes.object
};

export default Sidebar;
