import { DRAWER_STORAGE, TAB_MENU_STORAGE, INSTANCE_VALUE_STORAGE, LOCATION_VALUE_STORAGE } from 'store/actions/storageAction';

export const initialState = {
    tab_menu: 'block_storage',
    drawer: { open: false, flag: '' },
    instance: undefined,
    location: undefined
};

// ==============================|| STORAGE REDUCER ||============================== //

const storageReducer = (state = initialState, action) => {
    switch (action.type) {
        case TAB_MENU_STORAGE:
            return {
                ...state,
                tab_menu: action.payload
            };
        case DRAWER_STORAGE:
            return {
                ...state,
                drawer: action.payload
            };
        case INSTANCE_VALUE_STORAGE:
            return {
                ...state,
                instance: action.payload
            };
        case LOCATION_VALUE_STORAGE:
            return {
                ...state,
                location: action.payload
            };

        default:
            return state;
    }
};

export default storageReducer;
