import { lazy } from 'react';

// project imports
import Loadable from 'ui-component/loadable';
import MinimalLayout from 'layout/MinimalLayout';

// login option 3 routing
const AuthLogin3 = Loadable(lazy(() => import('views/pages/authentication/authentication3/login3')));
const AuthRegister3 = Loadable(lazy(() => import('views/pages/authentication/authentication3/register3')));
const Verification = Loadable(lazy(() => import('views/pages/authentication/authentication3/verification')));
const CompleteRegistration = Loadable(lazy(() => import('views/pages/authentication/authentication3/completeRegistration')));
const ForgotPassword = Loadable(lazy(() => import('views/pages/authentication/authentication3/forgotPassword')));
const Verify = Loadable(lazy(() => import('views/pages/authentication/authentication3/verify')));
const ResetPassword = Loadable(lazy(() => import('views/pages/authentication/authentication3/resetPassword')));
const TwoFA = Loadable(lazy(() => import('views/pages/authentication/authentication3/twoFactorAuth')));
const WhmcsRegistration = Loadable(lazy(() => import('views/pages/authentication/authentication3/whmcsRegistration')));
const GoogleRegistration = Loadable(lazy(() => import('views/pages/authentication/authentication3/googleOAuth')));
const PostpaidVerify = Loadable(lazy(() => import('views/pages/authentication/authentication3/postpaidCallback')));
const PostpaidRegistration = Loadable(lazy(() => import('views/pages/authentication/authentication3/postpaidRegistration')));

// ==============================|| AUTHENTICATION ROUTING ||============================== //

const AuthenticationRoutes = {
    path: '/',
    element: <MinimalLayout />,
    children: [
        {
            path: '/login',
            element: <AuthLogin3 />,
            exact: true
        },
        {
            path: '/register',
            element: <AuthRegister3 />,
            exact: true
        },
        {
            path: '/join/:code',
            element: <AuthRegister3 />,
            exact: true
        },
        {
            path: '/verification',
            element: <Verification />,
            exact: true
        },
        {
            path: '/complete-registration',
            element: <CompleteRegistration />,
            exact: true
        },
        {
            path: '/forgot-password',
            element: <ForgotPassword />,
            exact: true
        },
        {
            path: '/verify',
            element: <Verify />,
            exact: true
        },
        {
            path: '/reset-password',
            element: <ResetPassword />,
            exact: true
        },
        {
            path: '/2fa',
            element: <TwoFA />,
            exact: true
        },

        {
            path: '/whmcs/callback',
            element: <WhmcsRegistration />,
            exact: true
        },
        {
            path: '/google/callback',
            element: <GoogleRegistration />,
            exact: true
        },
        {
            path: '/postpaid',
            element: <PostpaidVerify />,
            exact: true
        },
        {
            path: '/postpaid/registration',
            element: <PostpaidRegistration />,
            exact: true
        }
    ]
};

export default AuthenticationRoutes;
