// React Redux
import { useSelector } from 'react-redux';

// third-party
import 'react-toastify/dist/ReactToastify.css';
import 'react-datepicker/dist/react-datepicker.css';
import { ToastContainer } from 'react-toastify';

// Material-UI
import { ThemeProvider } from '@mui/material/styles';
import { CssBaseline, StyledEngineProvider } from '@mui/material';

// Routing
import Routes from 'routes';

// Default Theme
import themes from 'themes';

// Project Imports
import NavigationScroll from 'layout/navigationScroll';
import { IntlProvider } from 'react-intl';
import { useEffect } from 'react';
import { ComponentWithEffect } from 'routes/checkUserInfo';

// ==============================|| APP ||============================== //
const messages = {
    en: {
        'currency.USD': 'United States dollar',
        'currency.IDR': 'Indonesian rupiah',
        'currency.EUR': 'Euro'
    },
    id: {
        'currency.USD': 'Dolar Amerika Serikat',
        'currency.IDR': 'Rupiah Indonesia',
        'currency.EUR': 'Euro'
    }
};

const App = () => {
    const customization = useSelector((state) => state.customization);
    const locale = 'id';

    useEffect(() => {
        const host = window.location.host;

        sessionStorage.setItem('app_name', host);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <StyledEngineProvider injectFirst>
            <IntlProvider locale={locale} messages={messages[locale]}>
                <ThemeProvider theme={themes(customization)}>
                    <CssBaseline />
                    <NavigationScroll>
                        <ToastContainer />
                        <Routes>{ComponentWithEffect()}</Routes>
                    </NavigationScroll>
                </ThemeProvider>
            </IntlProvider>
        </StyledEngineProvider>
    );
};

export default App;
