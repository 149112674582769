import { ERROR_MESSAGE, SUCCESS_MESSAGE } from 'store/actions/alertAction';

export const initialState = {
    errorMessage: { type: '', message: '', name: '' },
    successMessage: { type: '', message: '', name: '' }
};

// ==============================|| DASHBOARD REDUCER ||============================== //

const alertReducer = (state = initialState, action) => {
    switch (action.type) {
        case ERROR_MESSAGE:
            return {
                ...state,
                errorMessage: action.payload
            };
        case SUCCESS_MESSAGE:
            return {
                ...state,
                successMessage: action.payload
            };
        default:
            return state;
    }
};

export default alertReducer;
