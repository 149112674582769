import api from './api';

const requestPhoneVerification = () => {
    return api.post('/verification/phone', {
        action: 'verify'
    });
};

const changeUnverifiedPhoneNumber = (new_phone) => {
    return api.post('/verification/phone', {
        action: 'change',
        new_phone: new_phone
    });
};

const VerificationService = {
    requestPhoneVerification,
    changeUnverifiedPhoneNumber
};

export default VerificationService;
