import React from 'react';
// material-ui
import { useTheme, styled } from '@mui/material/styles';
import { Divider, Grid, List, ListItem, ListItemSecondaryAction, Typography } from '@mui/material';

// assets
import NotificationService from 'service/notification.service';

// styles
const ListItemWrapper = styled('div')(({ theme }) => ({
    cursor: 'pointer',
    padding: 16,
    '&:hover': {
        background: theme.palette.primary.light
    },
    '& .MuiListItem-root': {
        padding: 0
    }
}));

// ==============================|| NOTIFICATION LIST ITEM ||============================== //

// eslint-disable-next-line react/prop-types
const NotificationList = ({ listNotif, flag, notifStore }) => {
    const theme = useTheme();

    const handleClick = (res) => {
        NotificationService.readNotification(res.id).then(() => {
            if (res.redirect === null) {
                return null;
            } else {
                window.location.href = res.redirect;
            }
        });
    };

    return (
        <List
            sx={{
                width: '100%',
                maxWidth: flag ? null : 330,
                py: 0,
                borderRadius: '10px',
                [theme.breakpoints.down('md')]: {
                    maxWidth: flag ? null : 300
                },
                '& .MuiListItemSecondaryAction-root': {
                    top: 22
                },
                '& .MuiDivider-root': {
                    my: 0
                }
            }}
        >
            {
                // eslint-disable-next-line react/prop-types
                listNotif?.length === 0 || notifStore?.length === 0 ? (
                    <ListItemWrapper>
                        <Grid container direction="column" className="list-container">
                            <Grid item xs={12} sx={{ pb: 2, display: 'flex', alignItems: 'center' }}>
                                <Typography
                                    sx={{ maxWidth: '350px', paddingRight: '0' }}
                                    align="center"
                                    fontSize="16px"
                                    variant="subtitle2"
                                >
                                    You have no new notifications at this time.
                                </Typography>
                            </Grid>
                        </Grid>
                    </ListItemWrapper>
                ) : (
                    <>
                        {
                            // eslint-disable-next-line react/prop-types
                            listNotif?.map((res) => (
                                <React.Fragment key={res.id}>
                                    <ListItemWrapper
                                        onClick={() => handleClick(res)}
                                        sx={{ background: res.read_at === null ? theme.palette.primary.light : 'none' }}
                                    >
                                        <ListItem alignItems="center">
                                            <Typography fontWeight="bold" color="#1D243C" pb={1}>
                                                {res.title}
                                            </Typography>
                                            <ListItemSecondaryAction>
                                                <Grid container justifyContent="flex-end">
                                                    <Grid item xs={12}>
                                                        <Typography
                                                            sx={{ position: 'relative', top: '-10px' }}
                                                            variant="caption"
                                                            display="block"
                                                            gutterBottom
                                                        >
                                                            {res.created_at_hrf}
                                                        </Typography>
                                                    </Grid>
                                                </Grid>
                                            </ListItemSecondaryAction>
                                        </ListItem>
                                        <Grid container direction="column" className="list-container">
                                            <Grid item xs={12} sx={{ pb: 2 }}>
                                                <Typography variant="subtitle2">{res.content}</Typography>
                                            </Grid>
                                        </Grid>
                                    </ListItemWrapper>
                                    <Divider />
                                </React.Fragment>
                            ))
                        }
                    </>
                )
            }
        </List>
    );
};

export default NotificationList;
