import api from './api';
import TokenService from './token.service';

const register = (credential, password_confirmation, password, tos_agreement, referral_code) => {
    const data = {
        credential: credential,
        password: password,
        password_confirmation: password_confirmation,
        tos_agreement: tos_agreement,
        referral_code: referral_code,
        type: 'EMAIL',
        request_type: 'REGISTER'
    };
    return api.post('/auth/otp/request', data);
};

const otpLoginRequest = (credential) => {
    const data = {
        credential: credential,
        type: 'EMAIL',
        request_type: 'LOGIN'
    };
    return api.post('/auth/otp/request', data);
};

const otpLogin = (credential, code, remember_me) => {
    const data = {
        credential: credential,
        type: 'EMAIL',
        code: code,
        remember_me: remember_me
    };
    return api.post('/auth/otp/login', data);
};

const completeRegistration = (data) => {
    return api.post('/complete-registration', data);
};

const resendLink = (credential) => {
    const data = {
        credential: credential,
        type: 'EMAIL',
        request_type: 'REGISTER'
    };
    return api.post('/auth/otp/resend-link', data);
};

const requestResetPassword = (email) => {
    const data = {
        email: email
    };
    return api.post('/password/forgot', data);
};

const resetPassword = (email, token, password, password_confirmation) => {
    const data = {
        email: email,
        token: token,
        password: password,
        password_confirmation: password_confirmation
    };
    return api.post('/password/reset', data);
};

const verifyOtpRegistation = (hashCode) => {
    return api.post(`/auth/otp/register-verification?hash_code=${hashCode}`);
};

const otpRemainingResend = (credential) => {
    return api.get(`/auth/otp/remaining?credential=${credential}`);
};

const login = (email, password) => {
    return api
        .post('/auth/login', {
            email,
            password
        })
        .then((response) => {
            if (response.data) {
                TokenService.setUser(response.data);
            }

            return response.data;
        });
};

const checkAuth = () => {
    return api.get('/inf').then((response) => {
        return response.data;
    });
};

const startImpersonate = () => {
    return api.post('/auth/impersonate').then((response) => {
        return response.data;
    });
};

const stopImpersonate = () => {
    return api.post('/auth/stop-impersonate').then((response) => {
        return response.data;
    });
};

const logout = () => {
    return api.post('/auth/logout').then((response) => {
        TokenService.removeUser();
        return response.data;
    });
};

const getCurrentUser = () => {
    return JSON.parse(localStorage.getItem('user_info'));
};

const getRewardEligible = () => {
    return api.get('/reward/eligible').then((response) => {
        return response.data;
    });
};

const refreshToken = () => {
    return api.post('/auth/refresh');
};

//WHMCS
const loginViaWHMCS = () => {
    return api.get('/auth/whmcs/login').then((response) => {
        return response.data;
    });
};

const verifyLoginCallback = (data) => {
    return api.post('/auth/whmcs/verify-login', data).then((response) => {
        return response.data;
    });
};

//2FA
const verifyTwoFactorCodeAuth = (data) => {
    return api.post('/auth/2fa/verify-code', data).then((response) => {
        return response.data;
    });
};

const verifyTwoFactorRecoveryAuth = (data) => {
    return api.post('/auth/2fa/verify-recovery', data).then((response) => {
        return response.data;
    });
};

//Google
const loginGoogleOAuth = (data) => {
    return api.get('/auth/google', data).then((response) => {
        return response.data;
    });
};

const verifyGoogleOAuth = (data) => {
    return api.post('/auth/google/callback', data).then((response) => {
        return response.data;
    });
};

//Postpaid
const verifyInvitationPostpaid = (data) => {
    return api.post('/auth/invitation/verify', data).then((response) => {
        return response.data;
    });
};

const postPaidRegistration = (data) => {
    return api.post('/invitation/register', data).then((response) => {
        return response.data;
    });
};

const AuthService = {
    register,
    login,
    logout,
    getCurrentUser,
    checkAuth,
    resendLink,
    verifyOtpRegistation,
    completeRegistration,
    otpLogin,
    otpLoginRequest,
    otpRemainingResend,
    requestResetPassword,
    resetPassword,
    getRewardEligible,
    refreshToken,
    loginViaWHMCS,
    verifyLoginCallback,
    startImpersonate,
    stopImpersonate,
    verifyTwoFactorCodeAuth,
    verifyTwoFactorRecoveryAuth,
    loginGoogleOAuth,
    verifyGoogleOAuth,
    verifyInvitationPostpaid,
    postPaidRegistration
};

export default AuthService;
