export const formatPhoneNumber = (number) => {
    const cleanedNumber = number?.replace(/\D/g, '');

    if (!cleanedNumber) {
        return '';
    }

    if (cleanedNumber.startsWith('0')) {
        return '+62 ' + cleanedNumber.substring(1, 4) + ' ' + cleanedNumber.substring(4, 7) + '-' + cleanedNumber.substring(7);
    } else {
        const formattedNumber = '0' + cleanedNumber;
        return '+62 ' + formattedNumber.substring(1, 4) + ' ' + formattedNumber.substring(4, 7) + '-' + formattedNumber.substring(7);
    }
};
