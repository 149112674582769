import PropTypes from 'prop-types';
import { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';

// material-ui
import { useTheme } from '@mui/material/styles';
import { Collapse, List, ListItemButton, ListItemIcon, ListItemText, Menu, Typography } from '@mui/material';

// project imports
import NavItem from '../NavItem';

// assets
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';
import { IconChevronDown, IconChevronUp } from '@tabler/icons';

// ==============================|| SIDEBAR MENU LIST COLLAPSE ITEMS ||============================== //

const renderMenuItem = (item, level) => {
    switch (item?.type) {
        case 'collapse':
            return <NavCollapse key={item?.id} menu={item} level={level + 1} />;
        case 'item':
            return <NavItem key={item?.id} item={item} level={level + 1} />;
        default:
            return (
                <Typography key={item?.id} variant="h6" color="error" align="center">
                    Menu Items Error
                </Typography>
            );
    }
};

const NavCollapse = ({ menu, level }) => {
    const theme = useTheme();
    const customization = useSelector((state) => state.customization);
    const leftDrawerOpened = useSelector((state) => state.customization.opened);
    const tab = useSelector((state) => state.billingReducer.tab);

    const [open, setOpen] = useState(false);
    const [selected, setSelected] = useState(null);
    const [idMenu, setIdMenu] = useState(false);
    const [anchorEl, setAnchorEl] = useState(null);

    useEffect(() => {
        if (!leftDrawerOpened) {
            setOpen(false);
        }
    }, [leftDrawerOpened]);

    const handleClick = () => {
        setOpen(!open);
        setSelected(!selected ? menu.id : null);
    };

    const handleClickMenu = (event) => {
        if (anchorEl !== event.currentTarget) {
            setAnchorEl(event.currentTarget);
        }
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    useEffect(() => {
        const id = customization.isOpen || [tab];
        const isIdInMenu = id.some((itemId) => menu.children.some((item) => item.id === itemId));

        setIdMenu(isIdInMenu);
        if (!isIdInMenu) {
            setOpen(false);
            setSelected(null);
        } else {
            setOpen(!leftDrawerOpened ? false : true);
            setSelected(isIdInMenu ? menu.id : null);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [customization.isOpen]);

    // menu collapse & item
    const menus = menu?.children?.map((item) => renderMenuItem(item, level));
    const maxHeight = {
        2: '77%',
        3: '84%',
        4: '89%'
    };

    const menuSelect = selected === menu.id;
    const iconStyle = {
        position: menu.id === 'overview' ? 'relative' : '',
        left: menu.id === 'overview' ? '3px' : ''
    };

    const iconSrc = menuSelect ? menu.iconActive : menu.icon;

    // eslint-disable-next-line no-unused-vars
    const menuIcon = menu.icon ? (
        <img style={iconStyle} src={iconSrc} alt="" />
    ) : (
        <FiberManualRecordIcon
            sx={{
                width: menuSelect ? 8 : 6,
                height: menuSelect ? 8 : 6
            }}
            fontSize={level > 0 ? 'inherit' : 'medium'}
        />
    );

    const isSelected = menuSelect && idMenu;
    const isMenuVmAndClosed = menu.id === 'vm' && !leftDrawerOpened;

    const getVariant = () => {
        if (isSelected) {
            return 'h5';
        }
        return isMenuVmAndClosed ? null : 'body1';
    };

    const getColor = () => {
        if (isSelected) {
            return 'primary';
        }
        return isMenuVmAndClosed ? null : '#959595';
    };

    return (
        <>
            <ListItemButton
                sx={{
                    borderRadius: `${customization.borderRadius}px`,
                    mb: 0.5,
                    alignItems: 'flex-start',
                    backgroundColor: level > 1 ? 'transparent !important' : 'inherit',
                    py: level > 1 ? 1 : 1.25,
                    pl: `${level * 24}px`,
                    marginBottom: '10px'
                }}
                selected={selected === menu.id}
                onClick={!leftDrawerOpened ? null : handleClick}
            >
                {!leftDrawerOpened ? (
                    <>
                        <ListItemIcon onMouseOver={handleClickMenu} sx={{ my: 'auto', minWidth: !menu.icon ? 18 : 36 }}>
                            {menuIcon}
                        </ListItemIcon>
                        <Menu
                            id="simple-menu"
                            anchorEl={anchorEl}
                            open={Boolean(anchorEl)}
                            onClose={handleClose}
                            MenuListProps={{ onMouseLeave: handleClose }}
                        >
                            <List
                                component="div"
                                disablePadding
                                sx={{
                                    position: 'relative',
                                    '&:after': {
                                        content: "''",
                                        position: 'absolute',
                                        left: '32px',
                                        right: 0,
                                        top: 0,
                                        height: maxHeight[menus.length],
                                        width: '1.5px',
                                        opacity: 1,
                                        background: '#32439E'
                                    }
                                }}
                            >
                                {menus}
                            </List>
                        </Menu>
                    </>
                ) : (
                    <ListItemIcon sx={{ my: 'auto', minWidth: !menu.icon ? 18 : 36 }}>{menuIcon}</ListItemIcon>
                )}

                <ListItemText
                    sx={{ opacity: leftDrawerOpened ? 1 : 0 }}
                    primary={
                        <Typography variant={getVariant()} color={getColor()} sx={{ my: 'auto' }}>
                            {menu.id === 'vm' && !leftDrawerOpened ? null : menu.title}
                        </Typography>
                    }
                    secondary={
                        menu.caption && (
                            <Typography variant="caption" sx={{ ...theme.typography.subMenuCaption }} display="block" gutterBottom>
                                {menu.caption}
                            </Typography>
                        )
                    }
                />
                {open ? (
                    <IconChevronUp stroke={1.5} size="1rem" style={{ marginTop: 'auto', marginBottom: 'auto' }} />
                ) : (
                    <IconChevronDown stroke={1.5} size="1rem" style={{ marginTop: 'auto', marginBottom: 'auto' }} />
                )}
            </ListItemButton>

            <Collapse in={open} timeout="auto" unmountOnExit>
                <List
                    component="div"
                    disablePadding
                    sx={{
                        position: 'relative',
                        '&:after': {
                            content: "''",
                            position: 'absolute',
                            left: '32px',
                            right: 0,
                            top: 0,
                            height: maxHeight[menus.length],
                            width: '1.5px',
                            opacity: 1,
                            background: '#32439E'
                        }
                    }}
                >
                    {menus}
                </List>
            </Collapse>
        </>
    );
};

NavCollapse.propTypes = {
    menu: PropTypes.object,
    level: PropTypes.number
};

export default NavCollapse;
