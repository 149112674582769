// project imports
import {
    AMOUNT,
    CODE,
    DATA_INVOICE,
    MESSAGE_CREATE_INVOICE,
    METHOD,
    PAYMENT_METHOD,
    PAYMENT_METHOD_SELECTED,
    PHONE_NUMBER,
    REFRESH_INVOICE,
    STATUS_TOP_UP,
    TAB
} from 'store/actions/billingAction';

// action - state management

export const initialState = {
    payment_method: [],
    amount: 100000,
    code: '',
    phone_number: '',
    method: '',
    data_invoice: '',
    payment_method_selected: '',
    message_created_invoice: '',
    status_top_up: '',
    tab: 'top_up',
    refresh_invoice: false
};

// ==============================|| DASHBOARD REDUCER ||============================== //

const billingReducer = (state = initialState, action) => {
    switch (action.type) {
        case PAYMENT_METHOD:
            return {
                ...state,
                payment_method: action.payload
            };
        case AMOUNT:
            return {
                ...state,
                amount: action.payload
            };
        case CODE:
            return {
                ...state,
                code: action.payload
            };
        case PHONE_NUMBER:
            return {
                ...state,
                phone_number: action.payload
            };
        case METHOD:
            return {
                ...state,
                method: action.payload
            };
        case DATA_INVOICE:
            return {
                ...state,
                data_invoice: action.payload
            };
        case PAYMENT_METHOD_SELECTED:
            return {
                ...state,
                payment_method_selected: action.payload
            };
        case MESSAGE_CREATE_INVOICE:
            return {
                ...state,
                message_created_invoice: action.payload
            };
        case STATUS_TOP_UP:
            return {
                ...state,
                status_top_up: action.payload
            };
        case TAB:
            return {
                ...state,
                tab: action.payload
            };
        case REFRESH_INVOICE:
            return {
                ...state,
                refresh_invoice: action.payload
            };

        default:
            return state;
    }
};

export default billingReducer;
