import { lazy } from 'react';

// project imports
import MainLayout from 'layout/MainLayout';
import Loadable from 'ui-component/loadable';
import { useNavigate } from 'react-router';
import { useSelector } from 'react-redux';

// dashboard routing
// const DashboardDefault = Loadable(lazy(() => import('views/dashboard/Default')));

// utilities routing
const UtilsTypography = Loadable(lazy(() => import('views/utilities/typography')));
const UtilsColor = Loadable(lazy(() => import('views/utilities/color')));
const UtilsShadow = Loadable(lazy(() => import('views/utilities/shadow')));
const UtilsMaterialIcons = Loadable(lazy(() => import('views/utilities/materialIcons')));
const UtilsTablerIcons = Loadable(lazy(() => import('views/utilities/tablerIcons')));

// sample page routing
const SamplePage = Loadable(lazy(() => import('views/sample-page')));

//main menu
const Billing = Loadable(lazy(() => import('views/billing/index')));
const BillingReportDetail = Loadable(lazy(() => import('views/billing/report/detail')));
const Helpdesk = Loadable(lazy(() => import('views/helpdesk')));
const Overview = Loadable(lazy(() => import('views/overview')));
const VirtualMachines = Loadable(lazy(() => import('views/virtualMachines')));
const VirtualMachinesDetail = Loadable(lazy(() => import('views/virtualMachines/detail')));
const CreateVm = Loadable(lazy(() => import('views/virtualMachines/createVm')));
const CreateTicket = Loadable(lazy(() => import('views/helpdesk/createTicket')));
const ChatHelpdesk = Loadable(lazy(() => import('views/helpdesk/chatHelpdesk')));
const UpdateTicket = Loadable(lazy(() => import('views/helpdesk/updateHelpdesk')));
const Profile = Loadable(lazy(() => import('views/profile/index')));
const Notification = Loadable(lazy(() => import('views/notification')));
const Network = Loadable(lazy(() => import('views/network')));
const SSH = Loadable(lazy(() => import('views/authentication')));
const Storage = Loadable(lazy(() => import('views/storage')));

// ==============================|| MAIN ROUTING ||============================== //

const requireAuth = (Component, location) => {
    const AuthComponent = () => {
        const isSuspended = useSelector((state) => state.authReducer.user_info?.userinfo?.is_suspended);
        const navigate = useNavigate();

        if (!isSuspended) {
            return <Component />;
        } else {
            navigate(location);
            return null;
        }
    };

    AuthComponent.displayName = `requireAuth(${Component.displayName || Component.name || 'Component'})`;

    return AuthComponent;
};

// Wrap the CreateVm component with the requireAuth HOC
const CreateVmWithSuspended = requireAuth(CreateVm, '/instances');

// Wrap the VirtualMachinesDetail component with the requireAuth HOC
const DetailVmWithSuspended = requireAuth(VirtualMachinesDetail, '/instances');

const MainRoutes = {
    path: '/',
    element: <MainLayout />,
    children: [
        {
            path: '/',
            element: <Overview />,
            exact: true
        },
        {
            path: '/dashboard',
            element: <Overview />,
            exact: true
        },
        {
            path: 'utils',
            children: [
                {
                    path: 'util-typography',
                    element: <UtilsTypography />
                }
            ]
        },
        {
            path: 'utils',
            children: [
                {
                    path: 'util-color',
                    element: <UtilsColor />
                }
            ]
        },
        {
            path: 'utils',
            children: [
                {
                    path: 'util-shadow',
                    element: <UtilsShadow />
                }
            ]
        },
        {
            path: 'icons',
            children: [
                {
                    path: 'tabler-icons',
                    element: <UtilsTablerIcons />
                }
            ]
        },
        {
            path: 'icons',
            children: [
                {
                    path: 'material-icons',
                    element: <UtilsMaterialIcons />
                }
            ]
        },
        {
            path: 'sample-page',
            element: <SamplePage />
        },
        {
            path: 'billing',
            element: <Billing />,
            exact: true
        },

        {
            path: 'instances',
            element: <VirtualMachines />,
            exact: true
        },
        {
            path: 'instances/:name',
            element: <DetailVmWithSuspended />,
            exact: true
        },
        {
            path: 'instances/create-vm',
            element: <CreateVmWithSuspended />,
            exact: true
        },
        {
            path: 'helpdesk',
            element: <Helpdesk />,
            exact: true
        },
        {
            path: 'helpdesk/create-ticket',
            element: <CreateTicket />,
            exact: true
        },
        {
            path: 'helpdesk/chat/:token',
            element: <ChatHelpdesk />,
            exact: true
        },
        {
            path: 'helpdesk/update-ticket/:token',
            element: <UpdateTicket />,
            exact: true
        },
        {
            path: 'billing/detail/:date',
            element: <BillingReportDetail />,
            exact: true
        },
        {
            path: 'storage',
            element: <Storage />,
            exact: true
        },
        {
            path: 'profile',
            element: <Profile />,
            exact: true
        },
        {
            path: 'notification/view-all',
            element: <Notification />,
            exact: true
        },
        {
            path: 'network',
            element: <Network />,
            exact: true
        },
        {
            path: 'authentication',
            element: <SSH />,
            exact: true
        }
    ]
};

export default MainRoutes;
