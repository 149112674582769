import { Box, Button, Typography, useMediaQuery } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import IconReward from '../../../../assets/images/icons/box_reward.svg';
import VerificationService from 'service/verification.service';
import MainModal from 'ui-component/modal/mainModal';
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import mainBoxIcon from '../../../../assets/images/icons/verifyIcon.svg';
import { toast } from 'react-toastify';
import { formatPhoneNumber } from 'helpers/formatPhoneNumber';

const RewardBoxIcon = () => {
    const theme = useTheme();
    const dispatch = useDispatch();
    const open = useSelector((state) => state.authReducer.open_modal_reward);
    const matchesXs = useMediaQuery(theme.breakpoints.down('md'));

    const billingSystem = JSON.parse(localStorage.getItem('user_info'))?.userinfo?.billing;

    const [url, setUrl] = useState('');
    const [nomor, setNomor] = useState('');

    const requestPhoneVerification = () => {
        VerificationService.requestPhoneVerification().then(
            (res) => {
                const link = res?.data?.data?.whatsapp_url;
                const phoneNumber = res?.data?.data?.phone;
                setUrl(link);

                setNomor(phoneNumber ?? '');

                if (res.data.data.whatsapp_url !== null) {
                    dispatch({ type: 'OPEN_MODAL_REWARD', payload: { flag: 'header', open: true } });
                }
            },
            (error) => {
                toast.error(error.response.data.message, { position: 'top-center', theme: 'colored' });
            }
        );
    };

    const handleClaim = () => {
        requestPhoneVerification();
    };

    const handleVerifyNumber = () => {
        window.open(url, '_blank');
    };

    return (
        <>
            <Box
                onClick={handleClaim}
                display={billingSystem === 'PRE' ? 'flex' : 'none'}
                alignItems={'center'}
                justifyContent={'center'}
                sx={{
                    border: '1px solid #eeee',
                    padding: '7px',
                    borderRadius: '8px',
                    marginRight: matchesXs ? '0px' : '18px',
                    cursor: 'pointer',
                    '&:hover': {
                        background: '#EEF3FC'
                    }
                }}
            >
                <img src={IconReward} alt="" />
            </Box>

            {open.flag === 'header' ? (
                <MainModal
                    openModal={open.open}
                    handleCloseModal={() => dispatch({ type: 'OPEN_MODAL_REWARD', payload: { flag: 'header', open: false } })}
                    content={
                        <Box mt={2} mb={2}>
                            <Box mb={3} display={'flex'} justifyContent={'center'} alignItems={'center'}>
                                <img src={mainBoxIcon} alt="" />
                            </Box>
                            <Typography textAlign={'center'} fontWeight={'bold'} fontSize={'24px'} color={'#32439E'}>
                                Verify your number
                            </Typography>
                            <Typography mt={3} mb={3} textAlign={'center'} fontSize={'16px'} color={'#1D243C'}>
                                Click this button to generate whatsapp link and send the message to destined number with whatsapp account{' '}
                                <span style={{ color: '#32439E' }}> {formatPhoneNumber(nomor)}</span>.
                            </Typography>

                            <Button onClick={handleVerifyNumber} fullWidth color="primary" variant="contained">
                                Verify number
                            </Button>
                        </Box>
                    }
                />
            ) : null}
        </>
    );
};

export default RewardBoxIcon;
