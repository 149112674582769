// project imports

import {
    DATA_LOCATION,
    DATA_VSWITCH,
    EDGE,
    LOADING_FLOW,
    NODES,
    OPEN_DRAWER_NETWORK,
    OPEN_GRAPH,
    RESERVED_IP,
    TAB_NETWORK,
    UPDATE_FLOW,
    UUID
} from 'store/actions/networkAction';

// action - state management
export const initialState = {
    data_vswitch: [],
    uuid: '',
    edge: [],
    nodes: [],
    loading_flow: false,
    update_flow: false,
    open_graph: false,
    tab_network: 'private_network',
    open_drawer_network: { flag: '', open: false },
    data_location: [],
    reserved_ip: []
};

// ==============================|| DASHBOARD REDUCER ||============================== //

const networkReducer = (state = initialState, action) => {
    switch (action.type) {
        case DATA_VSWITCH:
            return {
                ...state,
                data_vswitch: action.payload
            };
        case UUID:
            return {
                ...state,
                uuid: action.payload
            };
        case EDGE:
            return {
                ...state,
                edge: action.payload
            };
        case NODES:
            return {
                ...state,
                nodes: action.payload
            };
        case LOADING_FLOW:
            return {
                ...state,
                loading_flow: action.payload
            };
        case UPDATE_FLOW:
            return {
                ...state,
                update_flow: action.payload
            };
        case OPEN_GRAPH:
            return {
                ...state,
                open_graph: action.payload
            };
        case TAB_NETWORK:
            return {
                ...state,
                tab_network: action.payload
            };
        case OPEN_DRAWER_NETWORK:
            return {
                ...state,
                open_drawer_network: action.payload
            };
        case DATA_LOCATION:
            return {
                ...state,
                data_location: action.payload
            };
        case RESERVED_IP:
            return {
                ...state,
                reserved_ip: action.payload
            };
        default:
            return state;
    }
};

export default networkReducer;
