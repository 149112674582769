import { TAB_AUTHENTICATION, OPEN_DRAWER_AUTHENTICATION } from 'store/actions/authenticationAction';

export const initialState = {
    tab: 'ssh',
    open_drawer: { open: false, flag: '' }
};

// ==============================|| DASHBOARD REDUCER ||============================== //

const authenticationReducer = (state = initialState, action) => {
    switch (action.type) {
        case TAB_AUTHENTICATION:
            return {
                ...state,
                tab: action.payload
            };
        case OPEN_DRAWER_AUTHENTICATION:
            return {
                ...state,
                open_drawer: action.payload
            };

        default:
            return state;
    }
};

export default authenticationReducer;
